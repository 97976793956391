import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import InfoDialog from '../InfoDialog'
import { CHRISTMAS_TIME } from '../../../config'
import { Box, makeStyles, Button, Typography } from '@material-ui/core'
import { getIsInitialLogin } from '../../../redux/selectors'

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
  },
}))

const XmassDialog = ({ isInitialLogin }) => {
  const [isOpen, setIsOpen] = useState(false)
  const localStorageKey = CHRISTMAS_TIME.CHRISTMAS_LOCAL_STORAGE_KEY
  const classes = useStyles()

  const isWithinChristmasDateRange = today => {
    const month = today.getMonth()
    const day = today.getDate()

    const inDecember = month === 11 && day >= CHRISTMAS_TIME.CHRISTMAS_START
    const inJanuary = month === 0 && day <= CHRISTMAS_TIME.CHRISTMAS_END

    return inDecember || inJanuary
  }

  const hasChristmasDialogAlreadyBeenShown = () => {
    return localStorage.getItem(CHRISTMAS_TIME.CHRISTMAS_LOCAL_STORAGE_KEY) !== null
  }
  const shouldShowChristmasDialog = () => {
    const today = new Date()
    return isWithinChristmasDateRange(today) && !hasChristmasDialogAlreadyBeenShown()
  }

  useEffect(() => {
    if (shouldShowChristmasDialog() && !isInitialLogin) {
      const openTimeout = setTimeout(() => {
        setIsOpen(true)
      }, 3000)

      return () => {
        clearTimeout(openTimeout)
      }
    }
  }, [isInitialLogin])

  const handleClose = () => {
    setIsOpen(false)
    localStorage.setItem(localStorageKey, 'true')
  }

  const christmasBody = (
    <>
      <span className={classes.boldText}>philoro</span> wünscht Ihnen und Ihren Lieben{' '}
      <span className={classes.boldText}>eine goldene Weihnachtszeit</span> und einen guten Start
      ins neue Jahr. Vielen Dank für Ihr Vertrauen!
      <br />
      <br />
      Während der Feiertage sind wir eingeschränkt erreichbar. Wir bemühen uns trotzdem, Ihre
      Anfragen so schnell wie möglich zu bearbeiten.
      <br />
      <br />
      Herzliche Grüße und eine erholsame Festzeit!
    </>
  )

  return (
    <InfoDialog
      open={isOpen}
      onClose={handleClose}
      title={CHRISTMAS_TIME.CHRISTMAS_MESSAGE_TITLE}
      infoText={
        <Box>
          <Typography>{christmasBody}</Typography>
          <Box style={{ textAlign: 'right', marginTop: '16px' }}>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Schließen
            </Button>
          </Box>
        </Box>
      }
    />
  )
}

const mapStateToProps = state => ({
  isInitialLogin: getIsInitialLogin(state),
})

export default connect(mapStateToProps)(XmassDialog)

import { Button, Grid, IconButton, Link as MuiLink, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import RemoveIcon from '@material-ui/icons/Remove'
import { Alert, Skeleton } from '@material-ui/lab'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import React, { useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  InfoDialog,
  InitialLoginDialog,
  PaymentsTable,
  SavingsPlanCard,
  SkeletonCard,
  SkeletonDashboardPercentageChange,
  SkeletonDepotOverview,
} from '..'
import config, {
  AVAILABLE_METALS,
  DAY_OF_MONTH,
  ROUTES,
  SAVING_PLAN_LOG_STATUS,
} from '../../config'
import useCurrency from '../../lib/customHooks/useCurrency'
import useDialog from '../../lib/customHooks/useDialog'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'
import { useLocalStorage } from '../../lib/customHooks/useLocalStorage'
import { useSpecialSumStyles } from '../../lib/customHooks/useSpecialSumStyles'
import { transformPlanDataForDashboardCard } from '../../lib/transform'
import { useTranslate } from '../../lib/translate'
import { performanceFormatter, sortPlans } from '../../lib/util'
import { storeCreatePlanValues } from '../../redux/actions'
import {
  getBackendCountryDepartmentData,
  getBackendInvoicesData,
  getBackendPerformancesData,
  getBackendSavingPlanLogsData,
  getBackendSavingsPlansData,
  isBackendPerformancesFetching,
  isBackendSavingsPlansFetching,
} from '../../redux/selectors'
import ApplyVoucherToSavingsPlanDialog from '../common/ApplyVoucherToSavingsPlanDialog'
import ChartContainer from '../common/ChartContainer'
import { WORKFLOW_PAGE as CREATE_WORKFLOW_PAGE_1 } from './createSavingsPlan/CreateSavingsPlanPage1'
import XmassDialog from '../common/XmassDialog/XmassDialog'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  percentageChangeContainer: {
    marginLeft: theme.spacing(2),
    display: 'flex',
  },
  percentageChange24h: {
    marginLeft: theme.spacing(0.5),
    color: props =>
      props?.x24hChange > 0
        ? theme.palette.success.main
        : props?.x24hChange < 0
        ? theme.palette.error.main
        : theme.palette.text.primary,
  },
  totalPerformance: {
    marginLeft: theme.spacing(0.5),
    color: props =>
      props?.totalPerformance > 0
        ? theme.palette.success.main
        : props?.totalPerformance < 0
        ? theme.palette.error.main
        : theme.palette.text.primary,
  },
  collectionAndBuyAlert: {
    marginBottom: '2rem',
  },
}))

const DashboardOverviewSumsGrid = ({ isFetching, hasPlans }) => {
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const [showDialogSumDepot, { openDialog: openDialogSumDepot, closeDialog: closeDialogSumDepot }] =
    useDialog()
  const [
    showDialogSumDeposit,
    { openDialog: openDialogSumDeposit, closeDialog: closeDialogSumDeposit },
  ] = useDialog()
  const { countryCode } = useSelector(getBackendCountryDepartmentData) || {}
  const { currentDepotValue, totalDepositValue } = useSelector(getBackendPerformancesData) || {}

  const totalAmount = displayCurrency(currentDepotValue)
  const classes = useSpecialSumStyles({ amountLength: totalAmount.replace('.', '').length })

  if (isFetching) {
    return <SkeletonDepotOverview />
  }

  if (!hasPlans && !currentDepotValue) {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="error">
          {translate('dashboard.noActivePlans')}
        </Typography>
        <XmassDialog />
      </Grid>
    )
  }

  const pricesLink =
    countryCode === 'DE' ? config.externalRoutes.pricesDE : config.externalRoutes.pricesAT

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={6} container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" className={`${classes.amount} ${classes.bold}`} noWrap>
            {totalAmount}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            noWrap
            paragraph
            component="div"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {translate('dashboard.sumDepot')}
            <IconButton style={{ marginLeft: 10 }} size="small" onClick={openDialogSumDepot}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.amount} noWrap>
            {displayCurrency(totalDepositValue)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            noWrap
            paragraph
            component="div"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {translate('dashboard.sumDeposit')}
            <IconButton style={{ marginLeft: 10 }} size="small" onClick={openDialogSumDeposit}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
      <InfoDialog
        open={showDialogSumDepot}
        onClose={closeDialogSumDepot}
        title={translate('dashboard.depotInfoTitle')}
        infoText={
          <Typography>
            {translate('dashboard.depotInfo')}{' '}
            <MuiLink color="secondary" href={pricesLink} target="_blank">
              {translate('dashboard.depotInfoLink')}
            </MuiLink>
          </Typography>
        }
      />
      <InfoDialog
        open={showDialogSumDeposit}
        onClose={closeDialogSumDeposit}
        title={translate('dashboard.sumDeposit')}
        infoText={<Typography>{translate('dashboard.depositInfo')}</Typography>}
      />
      <XmassDialog />
    </Grid>
  )
}

const DashboardOverview = ({ hasPlans }) => {
  const translate = useTranslate()
  const { totalPerformance } = useSelector(getBackendPerformancesData) || {}
  const isFetching = useSelector(isBackendPerformancesFetching)
  const classes = useStyles({ totalPerformance })
  const isUnderaged = useIsUnderaged()
  const [
    applyVoucherDialogState,
    { openDialog: openApplyVoucherDialog, closeDialog: closeApplyVoucherDialog },
  ] = useDialog()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container alignItems="center">
        <Typography variant="h6" className={classes.bold}>
          {translate('dashboard.depot')}
        </Typography>
        {isFetching ? (
          <SkeletonDashboardPercentageChange />
        ) : (
          <>
            <Typography variant="body2" className={classes.percentageChangeContainer}>
              {`${translate('dashboard.totalChange')}:`}
              <Typography variant="body2" className={classes.totalPerformance} component="span">
                {totalPerformance ? performanceFormatter.format(totalPerformance / 100) : '-'}
              </Typography>
            </Typography>
          </>
        )}
      </Grid>
      {isUnderaged && (
        <Grid item xs={12}>
          <Typography variant="caption" color="error">
            {translate('dashboard.underagedInfo')}
          </Typography>
        </Grid>
      )}
      <DashboardOverviewSumsGrid hasPlans={hasPlans} isFetching={isFetching} />
      <Grid item xs="auto">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          component={Link}
          to={ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE}
          disabled={isUnderaged}
        >
          {translate(`dashboard.${hasPlans ? 'createAnotherPlan' : 'createNewPlan'}`)}
        </Button>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<RemoveIcon />}
          component={Link}
          to={ROUTES.REQUEST_PAYOUT_STEP_ONE}
          disabled={isUnderaged}
        >
          {translate('transactions.toPayout')}
        </Button>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          component={Link}
          to={ROUTES.CREATE_DEPOSIT_STEP_ONE}
        >
          {translate('transactions.toDeposit')}
        </Button>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          disabled={isUnderaged}
          onClick={openApplyVoucherDialog}
        >
          {translate('transactions.toApplyVoucher')}
        </Button>
      </Grid>
      <ApplyVoucherToSavingsPlanDialog
        fullWidth
        open={applyVoucherDialogState}
        onClose={closeApplyVoucherDialog}
      />
    </Grid>
  )
}

const SavingsPlanCardsContainer = ({ children, isFetching, hasPlans }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.bold} paragraph>
          {isFetching ? (
            <Skeleton width={250} />
          ) : hasPlans ? (
            translate('dashboard.yourPlans')
          ) : (
            translate('dashboard.availablePlans')
          )}
        </Typography>
      </Grid>
      {isFetching ? (
        <>
          <Grid item xl={3} lg={4} md={6} xs={12}>
            <SkeletonCard />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12}>
            <SkeletonCard />
          </Grid>
        </>
      ) : (
        children
      )}
    </Grid>
  )
}

const PaymentsOverview = ({ hasPlans, data }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.bold} paragraph>
          {translate('dashboard.previousBuys')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {hasPlans ? (
          <PaymentsTable data={data} />
        ) : (
          <Typography variant="subtitle2">
            {translate('dashboard.transactionTextNoPlans')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

const CollectionAndBuyAlert = ({ todayDate, diffNextCollection, countryCode }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [isClosed, setIsClosed] = useLocalStorage('ld_ph_collectionAndBuyAlert', false)

  const content = useMemo(() => {
    let result = translate('alert.collectionAndBuy.generic')
    let when

    // [11, end of month]
    if (todayDate > DAY_OF_MONTH.BUY) {
      if (diffNextCollection < 5) {
        when = translate('alert.collectionAndBuy.inDays', {
          days: diffNextCollection,
        })
        result = translate('alert.collectionAndBuy.collect', {
          when,
        })
      }

      // [4, 9]
    } else if (todayDate > DAY_OF_MONTH.COLLECTION) {
      if (DAY_OF_MONTH.BUY - todayDate === 0) {
        when = translate('alert.collectionAndBuy.today')
      } else if (DAY_OF_MONTH.BUY - todayDate === 1) {
        when = translate('alert.collectionAndBuy.tomorrow')
      } else {
        when = translate('alert.collectionAndBuy.inDays', {
          days: DAY_OF_MONTH.BUY - todayDate,
        })
      }

      result = translate('alert.collectionAndBuy.buy', {
        when,
      })

      // [1, 3]
    } else {
      if (DAY_OF_MONTH.COLLECTION - todayDate === 0) {
        when = translate('alert.collectionAndBuy.today')
      } else if (DAY_OF_MONTH.COLLECTION - todayDate === 1) {
        when = translate('alert.collectionAndBuy.tomorrow')
      } else {
        when = translate('alert.collectionAndBuy.inDays', {
          days: DAY_OF_MONTH.COLLECTION - todayDate,
        })
      }

      result = translate('alert.collectionAndBuy.collect', {
        when,
      })
    }

    return result
  }, [todayDate, translate, diffNextCollection])

  if (isClosed) {
    return null
  }

  return (
    <Alert
      severity="info"
      variant="outlined"
      onClose={() => setIsClosed(true)}
      className={classes.collectionAndBuyAlert}
    >
      {content}
    </Alert>
  )
}

const DashboardPage = ({
  isFetchingPlans,
  plans,
  storeCreatePlanValues,
  invoices,
  savingPlanLogs,
}) => {
  const history = useHistory()
  const hasPlans = !isEmpty(plans)

  const { countryCode } = useSelector(getBackendCountryDepartmentData) || {}
  const sortedSavingPlanLogs = useMemo(
    () =>
      Array.isArray(savingPlanLogs)
        ? savingPlanLogs
            .filter(({ status }) => status === SAVING_PLAN_LOG_STATUS.OPEN)
            .sort((a, b) => new Date(a.changeDate) - new Date(b.changeDate))
        : [],
    [savingPlanLogs]
  )
  const sortedPlans = useMemo(() => (hasPlans ? sortPlans(plans) : undefined), [plans, hasPlans])

  const DefaultAvailablePlans = () =>
    AVAILABLE_METALS.map((item, i) => (
      <Grid item xl={3} lg={4} md={6} xs={12} key={`default-plans-${i}`}>
        <SavingsPlanCard
          content={item}
          button
          onClick={() => {
            storeCreatePlanValues({ [item.metalType]: true })
            history.push(ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE)
          }}
        />
      </Grid>
    ))

  const AvailablePlans = () =>
    hasPlans ? (
      sortedPlans.map((plan, i) => {
        const savingPlanLog = sortedSavingPlanLogs.find(
          ({ savingPlanId }) => savingPlanId === plan.id
        )

        return (
          <Grid item xl={3} lg={4} md={6} xs={12} key={`available-plans-${i}`}>
            <SavingsPlanCard
              content={transformPlanDataForDashboardCard(plan)}
              onClick={() => history.push(`${ROUTES.PLAN_DETAILS}/${get(plan, 'id')}`)}
              savingPlanLog={savingPlanLog}
            />
          </Grid>
        )
      })
    ) : (
      <DefaultAvailablePlans />
    )

  const today = moment()
  const todayDate = parseInt(today.format('D'), 10)

  const nextCollection = moment().add('1', 'month').date(DAY_OF_MONTH.COLLECTION)
  const diffNextCollection = nextCollection.diff(today, 'days')

  return (
    <>
      <CollectionAndBuyAlert
        todayDate={todayDate}
        diffNextCollection={diffNextCollection}
        countryCode={countryCode}
      />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <DashboardOverview hasPlans={hasPlans} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ChartContainer />
        </Grid>
        <Grid item xs={12}>
          <SavingsPlanCardsContainer hasPlans={hasPlans} isFetching={isFetchingPlans}>
            <AvailablePlans />
          </SavingsPlanCardsContainer>
        </Grid>
        <Grid item xs={12}>
          <PaymentsOverview hasPlans={hasPlans} data={invoices} />
        </Grid>
        <InitialLoginDialog />
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  isFetchingPlans: isBackendSavingsPlansFetching(state),
  plans: getBackendSavingsPlansData(state),
  invoices: getBackendInvoicesData(state),
  savingPlanLogs: getBackendSavingPlanLogsData(state) ?? [],
})

const mapDispatchToProps = dispatch => ({
  storeCreatePlanValues: values => dispatch(storeCreatePlanValues(CREATE_WORKFLOW_PAGE_1)(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
